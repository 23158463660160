import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {
    messageQueue, SubscriptionBill, SubscriptionSeat,
    AuthContext, User, Organization,
    ManagementConsoleAPIContext
} from "@ametektci/ametek.stcappscommon";
import axios, {AxiosResponse} from "axios";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import {textResources} from "../language/TextResources";
import {RemoveUserRequest, RemoveUserResponse} from "../Models/API/RemoveUserRequest";
import {InviteUserRequest, InviteUserResponse} from "../Models/API/InviteUserRequest";
import {
    NotifyUserOfPasswordChangeRequest,
    NotifyUserOfPasswordChangeResponse
} from "../Models/API/NotifyUserOfPasswordChangeRequest";
import {SetUserAdminRequest, SetUserAdminResponse} from "../Models/API/setUserAdminRequest";
import polly from "polly-js";

export const OrganizationContext = createContext({
    loading: false, //This should mostly sit as true, but is needed by App to know the difference between a new user and an organization that has not yet loaded.
    organization: undefined as Organization | undefined,
    user: undefined as User | undefined,
    billingItems: [] as Array<SubscriptionBill>,
    loadOrganization: () => Promise.resolve(),
    removeUser: (email: string) => Promise.resolve(false),
    inviteUserToOrganization: (email: string) => Promise.resolve(),
    reSendInvite: (userId: number) => Promise.resolve(),
    notifyUserOfPasswordChange: (email: string) => Promise.resolve(),
    setUserAdmin: (email: string, isAdmin: boolean) => Promise.resolve(),
    getOrganizationUsers: () => [] as Array<User>,
    updateUserProfile: (name: string, email: string) => Promise.resolve(),
    getOrganizationSeats: () => [] as Array<SubscriptionSeat>,
    getUserById: (id: number) => undefined as User | undefined,
    getUnauthorizedUsers: () => [] as Array<User>,
    getBlueSnapToken: () => Promise.resolve(""),
})

export function OrganizationContextWrapper(props: PropsWithChildren) {
    const auth = useContext(AuthContext)
    const mgmtApi = useContext(ManagementConsoleAPIContext)
    const user = mgmtApi.user
    const organization = mgmtApi.org
    const loading = !mgmtApi.userLoaded
    const [billingItems, setBillingItems] = useState<Array<SubscriptionBill>>([])
    const managementAxios = axios.create({
        baseURL: GetEnvironmentSetting('managementConsoleApi') as string
    })
    managementAxios.interceptors.request.use(async (req) => {
        req.headers.Authorization = "bearer " + await auth.getAccessToken()
        return req
    })
    useEffect(() => {
        if (auth.client == null)
            return
        if (auth.signedIn)
            loadOrganization()
        else
            onSignOut()
    }, [auth.signedIn])
    useEffect(() => {
        if (auth.signedIn)
            loadOrganization()
    }, [mgmtApi.org?.name])
    useEffect(() => {
        if (organization?.name != null)
        {
            loadBillingItems()
            loadCards()
        }
    }, [user?.username, organization?.name])
    const loadOrgData = async () => {
        await polly()
            .logger((err) => {
                console.error('Error loading organization details: ', err);
                setTimeout(() => {
                    messageQueue.sendError(textResources.Strings.errorFromServer, textResources.Strings.error);
                }, 1000);
            })
            .handle((err) => {
                if (err.response) {
                    return err.response.status !== 403 && err.response.status !== 404
                }
                return true;
            })
            .waitAndRetry([100,200,400]) //Give the database a chance to spin up
            .executeForPromise(async () => {
                return await mgmtApi.getUserInfo()
            })
    }
    const loadBillingItems = async () => {
        await polly()
            .logger((err) => {
                console.error('Error loading organization bills: ', err);
                setTimeout(() => {
                    messageQueue.sendError(textResources.Strings.errorFromServer, textResources.Strings.error);
                }, 1000);
            })
            .handle((err) => {
                if (err.response) {
                    return err.response.status !== 403 && err.response.status !== 404
                }
                return true;
            })
            .waitAndRetry([100,200,400]) //Give the database a chance to spin up
            .executeForPromise(async () => {
                return await managementAxios.get("/Bills");
            }).then((response) => {
                setBillingItems(response.data)
            })
    }
    const loadCards = async () => {
        await polly()
            .logger((err) => {
                console.error('Error loading organization payment options: ', err);
                setTimeout(() => {
                    messageQueue.sendError(textResources.Strings.errorFromServer, textResources.Strings.error);
                }, 1000)
            })
            .handle((err) => {
                if (err.response) {
                    return err.response.status !== 403 && err.response.status !== 404
                }
                return true;
            })
            .waitAndRetry([100,200,400]) //Give the database a chance to spin up
            .executeForPromise(async () => {
                return await managementAxios.post("/PaymentCards");
            })
    }
    const loadOrganization = async () => {
        await loadOrgData()
    }
    const onSignOut = () => {
        setBillingItems([])
    }
    const removeUser = async (email: string) => {
        let success = false
        try {
            let response = await managementAxios.post<RemoveUserResponse, AxiosResponse<RemoveUserResponse>, RemoveUserRequest>(GetEnvironmentSetting('managementConsoleApi') + "/RemoveUser", {
                UserEmail: email,
            })
            if (response.data.success)
                messageQueue.sendSuccess(textResources.Strings.removeUserSuccess);
            else
                messageQueue.sendError(textResources.Strings.removeUserFailure, textResources.Strings.error);
            success = response.data.success
        } catch (error) {
            console.log(error)
            messageQueue.sendError(textResources.Strings.unableToRemoveUser);
        }
        await loadOrganization()
        mgmtApi.getUserInfo()
        return success
    }
    const inviteUserToOrganization = async (userEmail: string) => {
        await managementAxios.post<InviteUserResponse, AxiosResponse<InviteUserResponse>, InviteUserRequest>( "/InviteUser", {
                UserEmail: userEmail,
            }).then((response) => {
            if (response.data.success) {
                messageQueue.sendSuccess(textResources.Strings.orgInviteSentSuccess.replace("***", userEmail));
            } else {
                messageQueue.sendError(textResources.Strings.orgInviteSentFailure.replace("***", userEmail), textResources.Strings.error);
            }
            loadOrganization()
        }).catch((error) => {
            console.log('error inviting user', error);
            messageQueue.sendError(textResources.Strings.orgInviteSentFailure.replace("***", userEmail), textResources.Strings.error);
        })
    }
    const reSendInvite = async (id: number) => {
        await managementAxios.post("/ReInviteUser", {
            userId: id
        })
    }
    const notifyUserOfPasswordChange = async (userEmail: string) => {
        try {
            await managementAxios.post<NotifyUserOfPasswordChangeResponse, AxiosResponse<NotifyUserOfPasswordChangeResponse>,
                NotifyUserOfPasswordChangeRequest>("/PasswordChanged", {
                    UserEmail: userEmail,
                }).then((response) => {
                if (!response.data.success)
                    console.error("Could not send email to" + userEmail)
            })
        } catch (error) {
            console.error(error)
        }
    }
    const setUserAdmin = async (userEmail: string, isAdmin: boolean) => {
        managementAxios.post<SetUserAdminResponse, AxiosResponse<SetUserAdminResponse>, SetUserAdminRequest>( "/SetUserAdmin", {
            UserEmail: userEmail,
            IsAdmin: isAdmin
        }).then((response) => {
            if (response.data.success) {
                messageQueue.sendSuccess(textResources.Strings.userAdminStatusChangeSuccess);

                let changedUser = mgmtApi.org!.users.find(u => u.email === userEmail);
                changedUser!.isAdmin = isAdmin;
            } else
                messageQueue.sendError(response.data.errorMessage, textResources.Strings.userAdminStatusChangeFailure);
            loadOrgData()
        }).catch((error) => {
            messageQueue.sendError(error.message, textResources.Strings.userAdminStatusChangeFailure);
        });
    }
    const updateUserProfile = async (name: string, email: string) => {
        let response = await managementAxios.post("/UpdateUserProfile", {
            Name: name,
            Email: email
        })

        if (response.data.success) {
            // if the email address changed, update in cognito as well
            if (user!.email !== email)
                mgmtApi.finishChangeEmail(email, "")

            user!.name = name;
            user!.email = email;
        } else {
            messageQueue.sendError(response.data.errorMessage, textResources.Strings.profileUpdateFailure);
        }
    }
    const getBlueSnapToken = async () => {
        try {
            let response = await managementAxios.post("/Billing/BlueSnapToken", {
            });

            return response.data.pfToken as string;
        } catch (error) {
            //@ts-ignore error is not properly typed
            messageQueue.sendError(error.message, textResources.Strings.blueSnapTokenFailure);
            return "";
        }
    }
    const getOrganizationSeats = () => {
        let seats = organization!.seats.filter(s => s.applicationId === 1) ?? [];

        for (let seat of seats) {
            if (!!seat.blueSnapSubscriptionId) {
                seat.subscription = billingItems!.find(b => b.subscriptionId === seat.blueSnapSubscriptionId)
            }
        }

        return seats
    }
    const getOrganizationUsers = () => {
        return organization!.users;
    }
    const getUserById = (userId: number) => {
        return organization!.users.find(u => u.userId === userId);
    }
    const getUnauthorizedUsers = () => {
        return organization!.users;
    }
    //user is authenticated and there is an organization
    return (<OrganizationContext.Provider value={{
        loading,
        organization,
        user,
        billingItems,
        removeUser,
        loadOrganization,
        inviteUserToOrganization,
        reSendInvite,
        notifyUserOfPasswordChange,
        setUserAdmin,
        getOrganizationUsers,
        updateUserProfile,
        getOrganizationSeats,
        getUnauthorizedUsers,
        getUserById,
        getBlueSnapToken,
    }}>
        {props.children}
    </OrganizationContext.Provider>)
}