import languageStrings from './languageStrings.json';
import moment from "moment";
import 'moment/locale/es-us';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/zh-cn';
import { SetLanguage, User} from "@ametektci/ametek.stcappscommon"

const supportedInternationalizations = [
    {browserCode: 'en', currencyCode: 'EUR', language: 'english'},
    //{browserCode: 'fr', currencyCode: 'EUR', language: 'french'},
    //{browserCode: 'de', currencyCode: 'EUR', language: 'german'},
    //{browserCode: 'es', currencyCode: 'EUR', language: 'spanish'}, //not sure if peso or euro... actually probably need both?
    //{browserCode: 'zh', currencyCode: 'CNY', language: 'chinese'}
] as const

const SupportedLanguages = [
    'english',
    //'french',
    //'german',
    //'spanish',
    //'chinese' // simplified
] as const;
type entries = keyof typeof languageStrings

export type Language = typeof supportedInternationalizations[number]["language"]
class TextResources {
    language: typeof SupportedLanguages[number];
    Strings: {[key in entries]: string};
    constructor() {
        // default to the browser language
        this.language = this.determineLanguage();
        //@ts-ignore - {} setstrings dose the work of fixing the strings object.
        this.Strings = {}
        this.setStrings()
    }
    private setStrings() {
        Object.keys(languageStrings).forEach((key) => {
            // @ts-ignore - I'm having some troubles with the type definition here.
            this.Strings[key as entries] = languageStrings[key as entries][this.language] ?? "Translate Me " + languageStrings[key as entries].english
        })
    }
    loadLanguage(user? : User)
    {
        //@ts-expect-error
        this.language = (!!user && user.language) || this.determineLanguage();

        // set the moment date locale
        this.setDateLocale(this.language);
        // set the strings to use
        this.setStrings()
        // send to stc apps common components
        SetLanguage(this.language)
    }

    determineLanguage() {
        // otherwise use what the browser uses
        let browserLanguage = window.navigator.language;

        let lang = supportedInternationalizations.find(i => browserLanguage.startsWith(i.browserCode))?.language ?? "english"

        // if its not a supported language, use english
        return lang;
    }

    setDateLocale(language : string) {
        let dateLocale = 'en-us';

        if (language === 'english')
            dateLocale = 'en-us';
        //else if (language === 'french')
        //    dateLocale = 'fr';
        //else if (language === 'german')
        //    dateLocale = 'de';
        //else if (language === 'spanish')
        //    dateLocale = 'es';
        //else if (language === 'chinese')
        //    dateLocale = 'zh-cn';

        moment.locale(dateLocale);
    }

    getCurrentLanguage() {
        return this.language;
    }
    getTextDynamic(text: string) : string
    {
        //textResources.Strings has a static typing due to Typescript's ability to handle json files
        //but Typescript is having a little trouble with the check, hence the verbosity.
        //The name will fill in if there is no translation for the name. Luckily,
        //the name will look weird and hopefully be easy to spot in development.
        if (text in this.Strings)
        {
            return this.Strings[text as keyof typeof this.Strings]
        }
        return `${text} NOT TRANSLATED`
    }
    setCurrentLanguage(language : string) {
        if (! SupportedLanguages.some(l => l === language))
            throw new Error("Language not supported");

        this.language = language as Language;
    }

    getSupportedLanguages() {
        return SupportedLanguages;
    }

}

export const textResources = new TextResources();